<template>
  <div>
    <back-button to="/admin/">Back to Settings</back-button>
    <b-card
      class="pt-3"
      style="max-width: 1000px"
      :style="loading ? 'cursor:wait' : 'cursor:default'"
    >
      <b-form-group label-cols-lg="3" label-size="lg">
        <template #label>
          Fee Schedule CSV Import
          <b-form-text class="mt-2 mr-3">
            <div class="mb-3"> Upload a CSV file containing fee schedule data. </div>
            <div class="mb-3"> Schedules will apply to all payers in the selected payer group. </div>
            <div class="mb-3"> File column format: <code>{{requiredColumnsString}}</code> </div>
          </b-form-text>
        </template>
        <template #description> 
          <strong>Warning: Files temporarily limited to first 100 rows for testing.</strong>
        </template>
        <b-form-group
          label="Practice"
          label-for="practice-multiselect"
          label-cols-sm="3"
          label-align-sm="right"
        >
          <practice-multiselect
            id="practice-multiselect"
            v-model="selectedPractice"
          />
        </b-form-group>
        <b-form-group
          label="Payer Group"
          label-for="payer-group-multiselect"
          label-cols-sm="3"
          label-align-sm="right"
        >
          <b-row>
            <b-col>
              <payer-group-multiselect
                id="payer-group-multiselect"
                v-model="selectedPayerGroup"
                :reload-options="reloadPayerGroupOptions"
              />
            </b-col>
            <b-col>
              <b-button
                v-b-modal.manage-payer-groups
                variant="outline-primary"
                class="avo-primary-btn"
                >Manage Groups</b-button
              >
            </b-col>
          </b-row>
        </b-form-group>
        <payer-manage-groups-modal @submitted="reloadPayerGroupOptions++" />
        <b-form-group
          label="Year"
          label-for="year-select"
          label-cols-sm="3"
          label-align-sm="right"
        >
          <b-form-select
            id="year-select"
            v-model="year"
            :options="yearOptions"
          />
        </b-form-group>
        <b-form-group
          label="File Upload"
          label-for="file-upload"
          label-cols-sm="3"
          label-align-sm="right"
          v-b-tooltip="fileUploadTooltip"
        >
          <b-form-file
            id="file-upload"
            v-model="file"
            :state="Boolean(file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
          <div>
            <b-alert
              v-model="showAlert"
              :variant="success ? 'success' : 'danger'"
              dismissible
            >
              <b>{{ alertMessage }}</b>
            </b-alert>
          </div>
          <div class="mt-3">Selected file: {{ file ? file.name : "" }}</div>
        </b-form-group>
        <div class="text-right">
          <b-button
            @click="onSubmit"
            class="avo-primary-btn"
            :disabled="loading"
            >Submit</b-button
          >
        </div>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from "../../common.js";
import BackButton from "../../components/BackButton.vue";
import PayerGroupMultiselect from "../multiselects/PayerGroupMultiselect.vue";
import PracticeMultiselect from "../multiselects/PracticeMultiselect.vue";
import PayerManageGroupsModal from "../payers/PayerManageGroupsModal.vue";

export default {
  name: "FeeScheduleCSVUpload",
  components: {
    BackButton,
    PayerGroupMultiselect,
    PayerManageGroupsModal,
    PracticeMultiselect,
  },
  mixins: [alertsMixin],
  data() {
    return {
      selectedPractice: null,
      year: 2022,
      file: null,
      loading: false,
      success: false,

      selectedPayerGroup: null,

      yearOptions: [2020, 2021, 2022, 2023],
      reloadPayerGroupOptions: 0,
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;
      this.hideAlert();
      if (this.isEmpty()) {
        this.alert("Please select a file");
        return;
      }
      if(this.file.size > 1024 * 1024 * 5) {
          this.alert("File size should be less than 5MB");
          return
      }
      const vm = this;
      const url = make_url(`/practices/${vm.practiceId}/fees/csv`);
      var bodyFormData = new FormData();
      bodyFormData.append("fee_schedule_csv", vm.file);
      bodyFormData.append("payer_group_id", vm.payerGroupId);
      bodyFormData.append("year", vm.year);
      const promise = authenticated_request({
        method: "post",
        url: url,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: bodyFormData,
      }).then(
        function (response) {
          vm.loading = false;
          console.log(response);
          vm.successAlert(
            "Successfully uploaded fee schedule CSV.\nYour import will continue processing in the background."
          );
          vm.file = null;
        },
        function (error) {
          vm.loading = false;
          vm.alert(error);
        }
      );
      return promise;
    },
    resetModal() {
      this.file = null;
    },
    isEmpty() {
      return Boolean(!this.file);
    },
    alert(message) {
      this.success = false;
      this.setShowAlert(true, message);
    },
    successAlert(message) {
      this.success = true;
      this.setShowAlert(true, message);
    },
    hideAlert() {
      this.success = false;
      this.setShowAlert(false);
    },
  },
  computed: {
    payerGroupId: function () {
      return this.selectedPayerGroup?.payer_group_id;
    },
    practiceId: function () {
      return this.selectedPractice?.practice_id;
    },
    fileUploadTooltip: function () {
      return {
        variant: 'dark',
        placement: 'right',
        title: `File shold contain columns: ${this.requiredColumnsString}`,
      }
    },
    requiredColumnsString: function () {
      return "code, modifiers, pos, tos, charge_fee, allowed_amount"
    },
  },
};
</script>
