<template>
    <div :class="{ 'invalid': state == false }">
        <multiselect
            openDirection="bottom"
            placeholder="Search by practice name" 
            :custom-label="practiceLabel"
            v-model="selectedPractice"
            track-by="practice_id"
            :options="selectablePractices"
            :loading="loading || practicesLoading"
            :close-on-select="closeOnSelect"
            :multiple="multiple"
            :show-labels="false"
            @search-change="debouncePractices"
            @open="debouncePractices"
            class="no-border-multiselect"
            :showNoOptions="true">
            <template slot="noResult">
                No practices matched your search
            </template>
            <template slot="noOptions">
                Start typing a practice name...
            </template>
        </multiselect>
    </div>
</template>

<script>
import { make_url, authenticated_request, debounce } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'PracticeMultiselect',
  components: { 
      Multiselect,
  },
  props: {
      value: [Object, Array],
      multiple: {
          type: Boolean,
          default: false,
      },
      closeOnSelect: {
          type: Boolean,
          default: false,
      },
      loading: {
          type: Boolean,
          default: false,
      },
      state: {
          type: Boolean || null,
          default: null,
      }
  },
  data: function(){
      return {
          selectablePractices: [],
          selectedPractice: null,
          practiceSearchText: "",
          practicesLoading: false,
      }
  },
  methods: {
    practiceSearchChange: function(searchQuery) {
        this.practicesLoading = true;
        this.practiceSearchText = searchQuery
        this.practiceAutocomplete();
    },
    practiceAutocomplete() {
        var vm = this;
        const url = make_url("/reports/practices/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.practiceSearchText || "",
                limit: 20
            }
        }).then(function(response){
            const practices = response.data.practices;
            vm.selectablePractices = practices || [];
            return practices || [];
        }).catch(function(errors){
            console.log(errors.response)
        }).finally(function(){
            vm.practicesLoading = false;
        });
    },
    practiceLabel(practice){
        if (practice.name){
            return practice.name
        } else {
            return practice.practice_id
        }
    },
  },
  watch: {
      selectedPractice() {
          this.$emit('input', this.selectedPractice)
      },
      value: {
          deep: true,
          immediate: true,
          handler: function(newValue) {
            if (newValue) {
                this.selectedPractice = newValue;
            }
          }
      },
  },
    created(){
        this.debouncePractices = debounce(this.practiceSearchChange, 300)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5 !important;
}
::v-deep .invalid .multiselect__tags {
    border:1px solid #DC3545;
    border-radius: 5px;
}
</style>