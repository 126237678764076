
<template>
  <multiselect
    openDirection="bottom"
    v-model="selectedGroup"
    :options="options"
    placeholder="Select a payer group"
    label="name"
    :searchable="true"
    :show-labels="false"
    track-by="payer_group_id"
  >
  </multiselect>
</template>

<script>
import { make_url, authenticated_request } from "../../common.js";
import Multiselect from "vue-multiselect";

export default {
  name: "PayerGroupMultiselect",
  components: {
    Multiselect,
  },
  props: {
    value: [Object, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    reloadOptions: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      options: [],
      selectedGroup: null,
      groupSearchText: "",
      optionsLoading: false,
    };
  },
  methods: {
    getGroups() {
      const url = make_url("/payers/groups");
      const vm = this;
      authenticated_request({
        method: "get",
        url: make_url("/payers/groups"),
      }).then(function (response) {
        const payersGroupsData = response.data.payers_groups;
        vm.options = payersGroupsData;
      });
    },
  },
  watch: {
    selectedGroup() {
      this.$emit("input", this.selectedGroup);
    },
    value: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.selectedGroup = newValue;
        }
      },
    },
    reloadOptions: {
      immediate: true,
      handler: function (newValue, oldValue) {
        if (newValue > oldValue) {
          this.getGroups();
        }
      },
    },
  },
  created() {
    this.getGroups();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .multiselect__spinner {
  background: #dde4f5 !important;
}
::v-deep .invalid .multiselect__tags {
  border: 1px solid #dc3545;
  border-radius: 5px;
}
</style>