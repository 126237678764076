<template>
<div id="payer-groups-manage">
<b-modal id="manage-payer-groups" @hide="resetModal" title="Manage Payer Groups" hide-footer size="md">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div class="d-flex justify-content-between">
            <div class="flex-fill mr-2">
                <b-form-group
                id="group"
                label="Select a payer group to edit">
                <b-input-group>
                    <multiselect
                        openDirection="bottom"
                        v-model="payer_group" 
                        :options="payer_groups"
                        placeholder="Select a payer group" 
                        label="name" 
                        :searchable="true"
                        :show-labels="false"
                        @select="new_group=null;"
                        track-by="payer_group_id">
                    </multiselect>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="flex-fill ml-2">
                <b-form-group
                    id="new_group"
                    label="Or create a new group">
                    <b-form-input placeholder="Give the new group a name" v-model="new_group" @change="payer_group=null;"></b-form-input>
                </b-form-group>
            </div>
        </div>
        <div v-if="(payer_group || new_group)">
            <b-form-group
            v-if="!loading_payers"
            id="payers"
            label="Select Payers for Group">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="selected_payers" 
                    :options="selectable_payers"
                    placeholder="Select payers" 
                    label="name"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :multiple="true"
                    :show-labels="false"
                    :allow-empty="true"
                    :internalSearch="true"
                    track-by="payer_id">
                </multiselect>
            </b-input-group>
            </b-form-group>
            <div v-else class="mt-2" style="text-align:center">
                <b-spinner></b-spinner>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
            <div>
                <b-button v-if="payer_group" @click="deleteGroup" class="avo-basic-btn mr-2">
                    Delete Group
                </b-button>
                <b-button @click="submitChanges" :disabled="(payer_group == null && new_group != null && new_group == '')" class="avo-primary-btn">
                    Submit Changes
                </b-button>
            </div>
        </div>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'PayerManageGroupsModal',
  components: {
      Multiselect
  },
  mixins: [alertsMixin],
  data: function(){
      return {
            payer_groups: [],
            payer_group: null,
            new_group: null,
            selectable_payers: [],
            selected_payers: null,
            loading_payers: true,
      }
  },
  methods: {
    getGroups(){
        const url = make_url("/payers/groups");
        const vm = this;
        authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            if (response) {
                vm.payer_groups = response.data.payers_groups;
                vm.getPayersWithNoGroup();
                if (vm.payer_group){
                    vm.getPayersInGroup()
                }
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    getPayersWithNoGroup(){
        const url = make_url("/payers/groups/payers");
        const vm = this;
        authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            if (response) {
                vm.selectable_payers = response.data.payers;
                if (!vm.payer_group){
                    vm.loading_payers = false;
                }
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    getPayersInGroup(){
        if (this.payer_group){
            const url = make_url("/payers/groups/payers");
            const vm = this;
            authenticated_request({
                method: "get",
                url: url,
                params: {
                    payer_group_id: this.payer_group.payer_group_id
                }
            }).then(function(response){
                if (response) {
                    vm.selected_payers = response.data.payers;
                    vm.selectable_payers = vm.selectable_payers.concat(response.data.payers);
                    vm.loading_payers = false;
                }
            }).catch(function(error){
                vm.showError(error);
            });
        }
        else{
            this.selected_payers = [];
        }
    },
    clearData(){
        this.payer_group = null;
        this.new_group = null;
        this.selected_payers = [];
        this.selectable_payers = [];
        this.hideError();
    },
    submitChanges(){
        if (this.new_group){
            this.createGroup();
        }
        else{
            this.addPayersToGroup(this.payer_group.payer_group_id)
        }
    },
    createGroup(){
        const url = make_url("/payers/groups");
        var vm = this;
        authenticated_request({
            method: "post",
            url: url,
            data: {
                name: this.new_group
            }
        }).then(function(response){
            if (response) {
                var group_id = response.data;
                vm.addPayersToGroup(group_id);
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    addPayersToGroup(payer_group_id){
        const url = make_url("/payers/groups/" + payer_group_id + "/payers");
        var vm = this;
        authenticated_request({
            method: "put",
            url: url,
            data: {
                payer_ids: this.selected_payers.map(obj => obj.payer_id).join(",")
            }
        }).then(function(response){
            if (response) {
                vm.getGroups();
                vm.$emit("submitted");
                vm.$bvModal.hide('manage-payer-groups');
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    deleteGroup(){
        const url = make_url("/payers/groups/" + this.payer_group.payer_group_id).toString();
        var vm = this;
        authenticated_request({
            method: "delete",
            url: url,
        }).then(function(response){
            if (response) {
                vm.getGroups();
                vm.$emit("submitted");
                vm.$bvModal.hide('manage-payer-groups');
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  watch:{
      payer_group: function(){
          this.loading_payers = true;
          this.selected_payers = [];
          this.getGroups();
      }
  },
  async mounted(){
      this.getGroups();
  }
}
</script>

<style scoped>

</style>